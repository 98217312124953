import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateFn,
  Params,
  Router,
  UrlTree,
} from '@angular/router';
import { UrlService } from '@services/auth-services/url.service';

@Injectable({
  providedIn: 'root',
})
export class ActionRedirectGuard implements CanActivate {
  constructor(private urlService: UrlService) {}

  canActivate(route: ActivatedRouteSnapshot, state: any): boolean | UrlTree {
    const mode = route.queryParams['mode'];
    const continueUrl = route.queryParams['continueUrl'];
    const url = new URL(continueUrl);
    const subdomain = url.searchParams.get('subdomain');

    switch (mode) {
      case 'resetPassword':
        return this.urlService.redirectToPath(
          subdomain,
          this.withQueryString('auth/reset-password', route.queryParams)
        );
    }

    return false;
  }

  private withQueryString(url: string, params: Params) {
    const queryString = this.buildQueryString(params);
    return [url, queryString].join('?');
  }

  private buildQueryString(params: { [key: string]: any }): string {
    const queryParts = Object.entries(params).map(
      ([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    );

    return queryParts.length ? `${queryParts.join('&')}` : '';
  }
}

const getPath = (input: string) => {
  if (input === 'resetPassword') {
    return '/auth/reset-password';
  } else if (input === '/auth/') {
  }

  return '';
};

export const authActionGuard: CanActivateFn = async (): Promise<boolean> => {
  const urlService = inject(UrlService);
  const settings = urlService.getUrlSettings();

  if (settings.subdomain) return true;

  if (settings.pathname !== '/auth/action') {
    return true;
  }

  const params = {
    continueUrl: settings.search?.get('continueUrl')!,
    apiKey: settings.search?.get('apiKey')!,
    oobCode: settings.search?.get('oobCode')!,
    mode: settings.search?.get('mode'),
    tenantId: settings.search?.get('tenantId'),
  };

  const continueUrl = urlService.parseUrl(params.continueUrl);
  const subdomain = continueUrl?.searchParams.get('subdomain');
  const path = getPath(params.mode!);
  const href = `${continueUrl.protocol}//${subdomain}.${continueUrl.host}${path}?oobCode=${params.oobCode}&apiKey=${params.apiKey}&subdomain=${subdomain}&tenantId=${params.tenantId}&continueUrl=${params.continueUrl}`;
  urlService.redirect(href);
  return false;
};
