import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface LoaderContent {
  title: string;
  message: string;
}

export enum LoaderState {
  None = 'None',
  InProgress = 'InProgress',
  Done = 'Done',
  Error = 'Error',
}

@Injectable({ providedIn: 'root' })
export class GlobalLoaderService {
  private readonly progressState = new BehaviorSubject<LoaderState>(LoaderState.None);
  private readonly content = new BehaviorSubject<LoaderContent | undefined>(undefined);

  getStatus() {
    return this.progressState.asObservable();
  }

  setStatus(status: LoaderState) {
    this.progressState.next(status);
  }

  getContent() {
    return this.content.asObservable();
  }

  setContent(content: LoaderContent) {
    return this.content.next(content);
  }

  reset() {
    this.content.next(undefined);
    this.progressState.next(LoaderState.None);
  }
}
