import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UrlService } from '@services/auth-services/url.service';
import { ProtectionService } from '@services/core-services/protection.service';
import { catchError } from 'rxjs/operators';

export const tenantGuard: CanActivateFn = (): Observable<boolean> => {
  const urlService = inject(UrlService);
  const protectionService = inject(ProtectionService);
  const { subdomain } = urlService.getUrlSettings();
  return protectionService.protectTenant(subdomain, true).pipe(catchError(() => of(false)));
};

export const tenantAndUserGuard: CanActivateFn = (): Observable<boolean> => {
  const urlService = inject(UrlService);
  const protectionService = inject(ProtectionService);
  const { subdomain } = urlService.getUrlSettings();
  return protectionService.protectTenantAndUser(subdomain).pipe(catchError(() => of(false)));
};
