import { inject } from '@angular/core';
import { CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of, tap } from 'rxjs';
import { UrlService } from '@services/auth-services/url.service';
import { ProtectionError, ProtectionService } from '@services/core-services/protection.service';
import { catchError } from 'rxjs/operators';
import { GlobalLoaderService, LoaderState } from '@services/core-services/global-loader.service';

const handleError = (
  router: Router,
  state: RouterStateSnapshot,
  error: ProtectionError,
  protectionService: ProtectionService,
  loaderService: GlobalLoaderService,
  urlService: UrlService
) => {
  console.log('Hybrid error', protectionService.getError());
  if (error === ProtectionError.UNKNOWN_TENANT) {
    loaderService.setContent({
      title: 'Engine Misfire!',
      message: 'Oops... it seems we couldn’t find your organization. Let’s get you back on track.',
    });
    loaderService.setStatus(LoaderState.Error);
    urlService.redirectToBasePath('signin');
    setTimeout(() => loaderService.setStatus(LoaderState.None), 1000);
  } else if (error === ProtectionError.MISSING_SUBDOMAIN) {
    router.navigate(['signin']).then(() => loaderService.setStatus(LoaderState.None));
  } else if (error === ProtectionError.UNKNOWN_USER) {
    router.navigate(['auth', 'login']).then(() => loaderService.setStatus(LoaderState.None));
  }

  return of(false);
};

export const userGuard: CanActivateFn = (route, state): Observable<boolean> => {
  const protectionService = inject(ProtectionService);
  const loaderService = inject(GlobalLoaderService);
  const urlService = inject(UrlService);

  const router = inject(Router);
  const { subdomain } = urlService.getUrlSettings();

  loaderService.setStatus(LoaderState.InProgress);
  loaderService.setContent({
    title: 'Revving Up the Engines...',
    message: 'Hold tight, we’re fueling your experience. Checking your organization’s details now.',
  });

  return protectionService.protectTenantAndUser(subdomain).pipe(
    tap(() => loaderService.setStatus(LoaderState.None)),
    catchError(error =>
      handleError(router, state, error, protectionService, loaderService, urlService)
    )
  );
};
