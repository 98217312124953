import { Injectable } from '@angular/core';

import { UrlSettings } from '@services/auth-services/models';
import { AuthService } from '@services/auth-services/auth.service';
import { TenantService } from '@services/tenant-services/tenant.service';
import { Tenant } from '@services/tenant-services/models';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private urlSettings: UrlSettings | undefined;

  constructor(
    private readonly authService: AuthService,
    private readonly tenantService: TenantService
  ) {}
  getUrlSettings(): UrlSettings | undefined {
    return this.urlSettings;
  }

  getTenant(): Tenant | undefined {
    return this.tenantService.getCurrentTenant();
  }

  loginWithEmailAndPassword(email: string, password: string, tenantId: string) {
    return this.authService.loginWithEmailAndPassword(email, password, tenantId);
  }

  loginWithSaml(tenantId: string) {
    return this.authService.loginWithSaml(tenantId);
  }

  loginWithGoogle(tenantId: string) {
    return this.authService.loginWithGoogle(tenantId);
  }

  loginWithMicrosoft(tenantId: string) {
    return this.authService.loginWithGoogle(tenantId);
  }

  loginWithGithub(tenantId: string) {
    return this.authService.loginWithGithub(tenantId);
  }

  // getStores(user: any) {
  //   return this.http.get(
  //     this.baseUrl + '/tenants/' + user?.tenantId + 'stores/',
  //     { uid: user?.uid },
  //     this.http.http_api_key()
  //   );
  // }
  //
  // switchStore(store_id: string | number) {
  //   return this.http.postjson(this.baseUrl + '/tenants/' + 'switch-store', {
  //     store_id: store_id,
  //   });
  // }
}
